<template>

				<v-card >					
          <v-card-text>
            <v-card style="width: 700px;">
              <v-card-title class="text-h4">Cambiar Contraseña</v-card-title>
              <div class="custom-line"></div>
                <br>
                <!--<v-divider style="border-width: 2px; border-color: #1976D2;"></v-divider>-->
                
                <v-card-text class="text-left">
                  <v-form ref="form" v-model="valid" lazy-validation  autocomplete="off">	
                    <Loading  v-if="isLoading" :isLoading="isLoading" :loadingMsg="loadingMsg" />	
                    <v-row class="mx-1">

                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        class="pa-0 px-2"
                      >
                        <div class="d-flex flex-column">
                          Contraseña Actual
                            <v-text-field                        
                              v-model="editedItem.password"
                              label=""
                              outlined
                              dense
                              :rules="[$rulesRequerido]"
                              :type="showPassword ? 'text' : 'password'"                       
                              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                              @click:append="togglePassword"
                            ></v-text-field>
                          </div>

                      </v-col>
                      </v-row>
                      <v-row   class="mx-1">
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            class="pa-0 px-2"
                          >
                            <div class="d-flex flex-column">
                              Nueva Contraseña
                              <v-text-field                                                  
                                v-model="editedItem.newpassword"
                                ref="newPassword" 
                                label=""
                                outlined
                                dense
                                :rules=" [$rulesRequerido, passwordMatchRule]"
                                autocomplete="new-password"
                                :type="showNewPassword ? 'text' : 'password'"                       
                                :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                @click:append="toggleNewPassword"
                              ></v-text-field>
                            </div>

                          </v-col>
                        </v-row>
                        <v-row class="mx-1">

                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            class="pa-0 px-2"
                          >
                            <div class="d-flex flex-column">
                              Confirmar Contraseña
                                <v-text-field 
                                  ref="passwordConfirm"                           
                                  v-model="editedItem.password_confirm"
                                  label=""
                                  outlined
                                  dense
                                  :rules=" [$rulesRequerido, passwordMatchRule]"
                                  :type="showConfirmPassword ? 'text' : 'password'"                       
                                  :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                  @click:append="toggleConfirmPassword"
                                ></v-text-field>
                              </div>

                          </v-col>
                        </v-row>																																																								
                  </v-form>
                </v-card-text>
                <v-card-actions>

                  <v-spacer></v-spacer>
                  <v-btn  color="white" @click="cancel" class="capitalize-first custom-btn">
                    Cancelar
                  </v-btn>
                  <v-btn   color="primary" :disabled="!(this.valid)" @click="confirmDialogSave()" class="capitalize-first custom-btn">
                    <v-icon left>mdi-content-save</v-icon>
                  Guardar
                  </v-btn>

                  </v-card-actions>
                  <dialog-confirmar
                    v-model="dialogs['confirmChangePassword']"
                    @input="dialog = $event"
                    title="Cambiar Contraseña"
                    :info="textInfoDialog"
                    icon="mdi-alert-circle-outline"
                    color="#1976D2"
                    @confirm="save()"
                  />
                  <dialog-validate
                    v-model="dialogs['errorChangePassword']"
                    @input="dialog = $event"
                    title="Cambiar Contraseña"
                    info="Se ha producido un error al cambiar la contraseña. Verifique los datos ingresados"
                    icon="mdi-alert-circle-outline"
                    color="#ffc107"
                  />
                  <dialog-completed
                    v-model="dialogs['successChangePassword']"
                    @input="dialog = $event"
                    title="Cambiar Contraseña"
                    info="Se ha modificado su contraseña"
                    icon="mdi-check-circle-outline"
                    color="#8fce00"
                  />
                              
              </v-card>
            
          </v-card-text>
        </v-card>
					
        
			
		
</template>

<script>
import DialogValidate from "@/views/dialogs/Validate.vue";
import DialogConfirmar from "@/views/dialogs/Confirmar.vue";
import DialogCompleted from "@/views/dialogs/FormCompleted.vue";
import Loading from "@/views/components/Loading.vue";
	export default {
    components: {    
    DialogConfirmar,
    DialogValidate,
    DialogCompleted,
    Loading
  },
		name: "UserProfile",

		data: (vm) => ({
			valid: true,
			route: "updatePass",									
			passwordValido:false,
      editedItem:{},
      showPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      dialogs: {        
        errorChangePassword: false,  
        confirmChangePassword: false,  
        successChangePassword: false    
      },
      textInfoDialog:'',
      isLoading: false,
      loadingMsg:"Cambiando contraseña",
		}),

		computed: {
      
      passwordMatchRule() {
      return v => {
        //if (!v) return 'Este campo es obligatorio';
        if(v){
          if (this.editedItem.newpassword && this.editedItem.password_confirm) {
            this.passwordValido=false;
            return this.editedItem.newpassword === this.editedItem.password_confirm || 'Las contraseñas no coinciden. Asegúrate de que ambas contraseñas sean idénticas';
          }
        }

        this.passwordValido=true;
        return true;
      };
    },
    },

		mounted() {
			console.log("this.$store.state.user.id");
			console.log(this.$store.state.user.id);
		},

		created() {
      console.log("this.$store.state.user.id");
			console.log(this.$store.state.user.id);
		},
    watch: {
      "editedItem.newpassword"(newValue) {
        this.$refs.passwordConfirm.validate();
      },
      "editedItem.password_confirm"(newValue) {
        this.$refs.newPassword.validate();
      }
    },
		methods:{

      confirmDialogSave(){
        if (this.$refs.form.validate()) {
          this.textInfoDialog = `¿Desea cambiar su contraseña?`;
          this.dialogs.confirmChangePassword = true;
        }
        
      },

      async save() {
        let vm = this;
        
        console.log(vm.editedItem);
        console.log(vm.$refs.form.validate());
        //return;
        if (vm.$refs.form.validate()) {
            
          //Swal.alertGetInfo("Actualizando información");
          this.isLoading = true;
          vm.editedIndex = this.$store.state.user.id;
          await vm.$axiosApi.put(vm.route, vm.editedIndex, vm.editedItem)
            .then((r) => {              
              this.isLoading = false;
              console.log(r);  
              this.dialogs.successChangePassword = true;  
                

            }).catch((e) => {
              this.isLoading = false;
              this.dialogs.errorChangePassword = true;
            });                       
        }
      },
      togglePassword() {
        this.showPassword = !this.showPassword;
      },
      toggleNewPassword() {
        this.showNewPassword = !this.showNewPassword;
      },
      toggleConfirmPassword() {
        this.showConfirmPassword = !this.showConfirmPassword;
      },
      cancel(){
        //this.$router.push({path:'/'});        
        this.$router.push({path:'/perfil'});                
        //window.location.href = '/';
      },   
			//
    }
	};
</script>

<style>

.card-border {
  border: 1.5px solid #DCDCDC !important
}

.card-span {
  margin-top: -15px;
  position: absolute;
  margin-left: 23px;
  background: white;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 18px;
  font-weight: 500;

}

.custom-btn {
  text-transform: none;
  font-family: inherit;
}

.custom-btn2 {
  text-transform: none;
  font-family: inherit;
  height: 39px !important;
  width: 19px !important;
  padding: 0px !important;
  margin: 0px;
}


.capitalize-first::first-letter {
  text-transform: capitalize;
}
.filtros-trans {
  margin-bottom: 4px;
}

.text-fields-row {
  display: flex;
}

.x-btn-grid {
  flex: unset;
  background-color: bisque;
  margin: 0 0px;
}

.acciones-width {
  width: 150px;
}

.swal2-container {
  z-index: 999999 !important;
}

.hint-celeste .v-messages__message {
  color: #1976D2 !important;
}

.check-codigo-cliente
{
  background-color:#ff0000;
}
.custom-line {
  height: 1.5px;
  background-color: #1976D2;
  margin: 8px 0;
}
/*
::v-deep(.v-messages__message) {
  color: #1976D2 !important;
}
*/
</style>
